const addWidthSvg = () => {
  setTimeout(() => {
    let tabs = document.querySelectorAll('.performance-tabs__tab');
    if (tabs){
      tabs.forEach(tab => {
        let width = tab.clientWidth;
        let item = tab.closest('.performance-tabs__item')
        let svg = item.querySelector('svg')
        if (width > 56){
          svg.style.transform = `scaleX(${width/56})`
        } else {
          svg.style.transform = `scaleX(1)`
        }
      })
    }

    let mobileLinks = document.querySelectorAll('.links-slider__mobile-hover')
    if (mobileLinks){
      mobileLinks.forEach(link => {
        let width = link.clientWidth;
        let svg = link.querySelector('svg');
        if (width > 56){
          svg.style.transform = `scaleX(${width/56})`
        } else {
          svg.style.transform = `scaleX(1)`
        }
      })
    }

    let selectedLinkSlide = document.querySelector('.swiper-slide--selected')
    if (selectedLinkSlide){
      let mobileHover = selectedLinkSlide.querySelector('.links-slider__mobile-hover')
      if (mobileHover){
        let svg = mobileHover.querySelector('svg')
        svg.style.opacity = "1"
      }
    }

    let tabsLinks = document.querySelectorAll('.links-slider__slide');
    if (tabsLinks){
      tabsLinks.forEach(tab => {
        if (window.innerWidth > 1279){
          let width = tab.clientWidth;
          let svg = tab.querySelector('svg')
          let path = svg.querySelector('.squiggle')
          if (width > 500){
            svg.style.transform = `scaleX(${width/321})`;
            svg.style.opacity = `1`;
          } else {
            svg.style.transform = `scaleX(1.01)`;
            path.style.transform = `scaleX(${width/329}) scaleY(0.5)`
            svg.style.opacity = `1`;
          }
        }
      })
    }
  },100)

}

document.addEventListener('DOMContentLoaded', addWidthSvg)
document.addEventListener('load', addWidthSvg)
window.addEventListener('resize', addWidthSvg)

