document.addEventListener('DOMContentLoaded', () => {
  let inputs = document.querySelectorAll('.search-block__input');
  if (inputs){
    inputs.forEach(input => {
      input.addEventListener('blur', () => {
        let parent = input.parentElement

        if (input.value){
          if (parent){
            let label = parent.querySelector('.search-block__label')
            label.classList.add('search-block__label--checked')
          }
        } else {
          if (parent){
            let label = parent.querySelector('.search-block__label')
            if (label.classList.contains('search-block__label--checked'))
            label.classList.remove('search-block__label--checked')
          }
        }
      })
    })
  }
})
