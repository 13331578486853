document.addEventListener("DOMContentLoaded", () => {
  let filtersBtn = document.querySelectorAll('.mobile-filters-js');
  let mobileFilters = document.querySelector('.mobile-filters');
  let mobileFiltersContent = mobileFilters && mobileFilters.querySelector('.mobile-filters__content');
  let filtersFormCount = document.querySelector('.filters-form__count');


  let closeFilters = () => {
    mobileFilters.classList.remove('mobile-filters--active')
    document.body.classList.remove('overflow-hidden')
  }

  const getCountFilters = () => {
    if (mobileFiltersContent){
      let count = mobileFiltersContent.querySelectorAll('input:checked')
      filtersFormCount.textContent = String(count.length)
    }
  }

  getCountFilters()
  if (filtersBtn){
    filtersBtn.forEach(btn => {
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        let activeMobileFilters = document.querySelector('.mobile-filters--active');
        const togglePopup = () => {
          mobileFilters.classList.toggle('mobile-filters--active')
          document.body.classList.toggle('overflow-hidden')
        }
        if (mobileFilters){
          togglePopup()
          if (!activeMobileFilters){
            mobileFilters.addEventListener('click', (e) => {
              if (e.target === mobileFilters){
                mobileFilters.classList.remove('mobile-filters--active')
                document.body.classList.remove('overflow-hidden')
              }
              if (e.target.classList.contains('filter__input')){
                getCountFilters()
              }
            })
          }
        }
        let closeBtn = document.querySelector('.close-filters-js');
        if (closeBtn){
          closeBtn.addEventListener('click', (e) => {
            e.preventDefault()
            closeFilters()
          })
        }
        let mobileFiltersFooter = mobileFilters.querySelector('.mobile-filters__footer')
        if (mobileFiltersFooter){
          let mobileFiltersSubmit = mobileFiltersFooter.querySelector('.button')
          if (mobileFiltersSubmit){
            mobileFiltersSubmit.addEventListener('click',(e) => {
              e.preventDefault()
              closeFilters()
            })
          }
        }
      })
    })
  }

})

