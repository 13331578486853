const changeFontSize = () => {
  let words = document.querySelectorAll('.font-size-js')
  if (words){
    words.forEach(word => {
      if (window.innerWidth < 780){
        if (word.clientWidth > 163 && word.clientWidth <= 251){
          word.classList.add('area-block__word--not-large')
        } else if (word.clientWidth > 558){
          word.classList.add('area-block__word--very-small')
        } else if (word.clientWidth > 251){
          word.classList.add('area-block__word--small')
        }
      } else if (window.innerWidth < 1280 && window.innerWidth > 779){
        if (word.clientWidth > 278 && word.clientWidth <= 770){
          word.classList.add('area-block__word--not-large')
        } else if (word.clientWidth > 1115){
          word.classList.add('area-block__word--very-small')
        } else if (word.clientWidth > 770){
          word.classList.add('area-block__word--small')
        }
      } else
      if (window.innerWidth > 779){
        if (word.clientWidth > 325 && word.clientWidth <= 770){
          word.classList.add('area-block__word--not-large')
        } else if (word.clientWidth > 1115){
          word.classList.add('area-block__word--very-small')
        } else if (word.clientWidth > 770){
          word.classList.add('area-block__word--small')
        }
      } else {

      }

    })
  }
}
document.addEventListener('DOMContentLoaded', changeFontSize)
window.addEventListener('resize', changeFontSize)
