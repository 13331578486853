    document.addEventListener('DOMContentLoaded', () => {
  let tabs = document.querySelectorAll('.afisha-tabs')
  if (tabs){
    tabs.forEach(el => {
      let inputs = el.querySelectorAll('input.afisha-tabs__input');
      inputs.forEach((input,index) => {
        input.addEventListener('click', (e) => {
          let checkedItem = document.querySelector('.afisha-tabs__catalog').querySelector('.afisha-tabs__item--checked')
          if (checkedItem){
            checkedItem.classList.remove('afisha-tabs__item--checked')
          }
          let label = e.target.parentElement.querySelector('label')
          if (label){
            label.classList.toggle('afisha-tabs__item--checked')
          }

          let checkedTab = document.querySelector('.afisha-tabs__checked')
          if(checkedTab){
            checkedTab.classList.remove('afisha-tabs__checked')
          }
          let foundArr = e.target.closest('.afisha-tabs').querySelectorAll('.afisha-tabs__content');
          if (foundArr && foundArr[index]){
            foundArr[index].classList.add('afisha-tabs__checked')
          }

        })

      })

      // //Если вместо табов dropdown-блоки
      let items = document.querySelectorAll('.afisha-tabs__item--mobile');
      if (items){
        items.forEach((item, index) => {
          item.addEventListener('click', (e) => {
            document.querySelector('.afisha-tabs__checked').classList.remove('afisha-tabs__checked')
            let foundArr = e.target.closest('.afisha-tabs').querySelectorAll('.afisha-tabs__content');
            if (foundArr && foundArr[index]){
              setTimeout(() => {
                foundArr[index].classList.add('afisha-tabs__checked')
              }, 1)
            }
          })
        })
      }
    })
  }
})
