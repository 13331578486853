document.addEventListener("scroll", function(){
  let fixHeader = document.querySelector(".header--fix-header");
  if (fixHeader){
    if (window.scrollY >= 100){
      fixHeader.classList.add('header__fix-opened')
    } else {
      fixHeader.classList.remove('header__fix-opened')
    }
  }
});
