import './tabs.js';

document.addEventListener('DOMContentLoaded', () => {
  let buttons = document.querySelectorAll('.video-btn-js');
  if (buttons){
    buttons.forEach(el => {
      el.addEventListener('click', () => {
        el.style.display = "none"
        let preview = el.closest('.video-preview-js')
        preview.querySelector('img').style.display = "none"
        let video = preview.querySelector('video');
        let iframeVideo = preview.querySelector('iframe');
        if (video){
          video.style.opacity = "1"
        }
        if (iframeVideo){
          iframeVideo.style.opacity = "1"
        }
        let stopVideo = () => {
          window.addEventListener('scroll', (e) => {
            if (window.scrollY > 1552){
              const videoAboutUs = document.querySelector('.video-preview-js');
              let iframe = videoAboutUs.querySelector('iframe');
              if (iframe){
                let oldSrc = iframe.getAttribute('src');
                if (!oldSrc.includes('youtube')) {
                  let newSrc = oldSrc
                    .replace('&js_api=1', '')
                    .replace('&loop=1', '')
                  iframe.setAttribute('src', newSrc);
                }else
                  iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
              }

              let video = videoAboutUs.querySelector('video')
              if (video){
                video.pause();
                video.currentTime = 0;
              }

            }
          })

        }

        if (video){
          video.style.opacity = '1'
          video.style.zIndex = '1'
          video.play()
          stopVideo()
        }
        let iframe = preview.querySelector('iframe');
        if (iframe){
          iframe.style.opacity = '1'
          iframe.style.zIndex = '1'
          let oldSrc = iframe.getAttribute('src');
          let newSrc;
          if (oldSrc){
            newSrc = oldSrc;
            if (newSrc.includes('vk.com')){
              if (newSrc.includes('?')){
                newSrc = oldSrc+'&js_api=1&loop=1'
              } else {
                newSrc = oldSrc+'?js_api=1&loop=1'
              }
              iframe.setAttribute('src', newSrc);
              let vkPlayer = VK.VideoPlayer(iframe);
              vkPlayer.play()
            }

            if (newSrc.includes('youtube')){
              iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
            }
          }

          stopVideo()
        }
      })
    })
  }
})
