import {gsap} from "gsap";

document.addEventListener('DOMContentLoaded', () => {
  let themes = document.querySelectorAll('.theme-js');
  themes.forEach(theme => {
    theme.addEventListener('click', () => {
      let themesList = document.querySelector('.form-review__themes-list')
      if (theme.classList.contains('form-review__theme--active')){
        gsap.to(themesList, {height: 0})
      } else {
        gsap.to(themesList, {height: 'auto'})
      }
      theme.classList.toggle('form-review__theme--active')
    })
  })

  // let themesList = document.querySelector('.form-review__themes-list')
  // let radioInputs = themesList.querySelectorAll('input')
  // if (radioInputs){
  //   radioInputs.forEach(el => {
  //     el.addEventListener('change', () => {
  //       console.log('change')
  //     })
  //   })
  // }
  //
  // let themeLabels = document.querySelectorAll('.form-review__theme-label')
  // if (themeLabels){
  //   themeLabels.forEach(label => {
  //     label.addEventListener('')
  //   })
  // }

})
