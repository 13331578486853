import Swiper from "swiper";
import {logPlugin} from "@babel/preset-env/lib/debug";

//табы
let tabsSwiper;
let galleryThumbs
let activeIndexTab
const tabsInit = (initialSlide) => {
//табы
  const tabsSlider = document.querySelector('.performance-tabs__container');
  let tabsWrapper;
  let tabsSlides
  if(tabsSlider){
    tabsWrapper = tabsSlider.querySelector('.swiper-wrapper');
    tabsSlides = tabsSlider.querySelectorAll('.swiper-slide')
  }



  if (tabsSlider) {
    galleryThumbs = new Swiper(tabsSlider, {
      initialSlide:initialSlide,
      speed: 0,
      slidesPerView:'auto',
      spaceBetween: 48,
      allowTouchMove: false,
      autoScrollOffset: 1,
      autoHeight:false,
      observer: true,
      observeParents: true,
      resizeObserver:true,
      watchOverflow: true,
      clickedSlide: true,
      centeredSlides: false,
      centeredSlidesBounds: false,
      slideToClickedSlide: true,
      slidesOffsetAfter: 0,
      loopFillGroupBlank: false,
      preventClicksPropagation:true,

      breakpoints:{
        360:{
          slidesPerView: 'auto',
          spaceBetween: 50,
          centeredSlides: true,
          centeredSlidesBounds: true,
          watchSlidesProgress: true,
          watchOverflow: true,
        },
        780:{
          slidesPerView: (tabsSlides.length === 3) ? 3 : 'auto',
          centeredSlides: tabsSlides.length !==2 ,
          centeredSlidesBounds: tabsSlides.length !==2,
          spaceBetween: 106,
        },
        1000:{
          allowTouchMove: false,
        },
        1280: {
          slidesPerView:4,
          centeredSlides: false,
          centeredSlidesBounds: false,
          spaceBetween: 48,
          allowTouchMove: false,
        },
      },
      on: {
        init() {
          tabsSlider.click()
          if (tabsWrapper && tabsSlides && tabsSlides.length === 2) {
            tabsWrapper.style.justifyContent = "flex-start"
          }
        },
      },
    })
    const contentSlider = document.querySelector('.performance-tabs__content-block');

    if (contentSlider) {
      tabsSwiper = new Swiper(contentSlider, {
        initialSlide:initialSlide,
        slidesPerView: 1,
        spaceBetween: 40,
        autoHeight: true,
        speed: 0,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        resizeObserver:true,
        navigation: {
          prevEl: '.performance-tabs__nav-prev',
          nextEl: '.performance-tabs__nav-next',
        },
        thumbs: {
          swiper: galleryThumbs
        },
        allowTouchMove: false,
        on: {
          init:function (){
            setTimeout(() => {
              let activeIndex = tabsSwiper.activeIndex
              galleryThumbs.slideTo(activeIndex)
              if (initialSlide){
                tabsSwiper.slideTo(initialSlide)
                tabsSwiper.update()
              }
            },100)
          },
          update:function (){
            setTimeout(() => {
              let catalog = document.querySelector('.performance-tabs__catalog')
              if (!tabsSwiper.isBeginning && !tabsSwiper.isEnd && window.innerWidth > 779){
                catalog.style.marginLeft = galleryThumbs.previousTranslate + "px"
              }
              else if (tabsSwiper.isBeginning){
                catalog.style.marginLeft = Math.abs(galleryThumbs.previousTranslate) + "px"
              } else {
                catalog.style.marginLeft = "0"
              }
              let activeIndex = tabsSwiper.activeIndex
              if (tabsSwiper) galleryThumbs.slideTo(activeIndex)
            },200)
          },

          slideChange: function (){
            let catalog = document.querySelector('.performance-tabs__catalog')
            if (window.innerWidth < 1280 && !tabsSwiper.isBeginning){
              let activeIndex = tabsSwiper.activeIndex
              galleryThumbs.slideTo(activeIndex)
              galleryThumbs.update()
            }
            if (window.innerWidth < 780){
              if (tabsSwiper.isBeginning){
                galleryThumbs.params.centeredSlidesBounds = true
                galleryThumbs.update()
              } else if (tabsSlides.length === 3 && !tabsSwiper.isEnd){
                galleryThumbs.params.centeredSlidesBounds = true
                galleryThumbs.update()
              } else {
                galleryThumbs.params.centeredSlidesBounds = false
                galleryThumbs.update()
              }
            }
            if (tabsSwiper.isBeginning && tabsSlides.length === 2){
              catalog.style.marginLeft = "0"
            } else
            if (tabsSwiper.isBeginning && window.innerWidth > 779 && window.innerWidth < 1280){
              catalog.style.marginLeft = Math.abs(galleryThumbs.previousTranslate) + "px"

            } else if (!tabsSwiper.isBeginning && !tabsSwiper.isEnd && window.innerWidth > 779){
              catalog.style.marginLeft = galleryThumbs.previousTranslate + "px"
            } else {
              catalog.style.marginLeft = "0"
            }
            activeIndexTab = tabsSwiper.activeIndex
            return activeIndexTab
          }
        },
      })
      tabsSwiper.init()
      setTimeout(() => {
        tabsSwiper.update()
      },500)

    }
  }
}

const resizeHandlerSlider = () => {
  setTimeout(() => {
    if (tabsSwiper){
      activeIndexTab = tabsSwiper.activeIndex
      tabsSwiper.destroy()
      tabsInit(activeIndexTab)
    }
  },100)
  setTimeout(() => {
    const tabsSlider = document.querySelector('.performance-tabs__container')
    if(tabsSlider){
      tabsSlider.click()
    }
  },200)



}
document.addEventListener('DOMContentLoaded', () => {
  tabsInit(0)
})
window.addEventListener('resize', () => {
  resizeHandlerSlider()
})

let getSliders = () => {
  const footerSlider = document.querySelector('.footer__slider');
  if (footerSlider){
    let swiper = new Swiper(footerSlider, {
      slidesPerView: 'auto',
      spaceBetween: 37,
      navigation: {
        prevEl: '.footer__slider-prev',
        nextEl: '.footer__slider-next',
      },
      breakpoints:{
        360:{
          slidesPerView: 1,
          spaceBetween: 51,
        },
        780:{
          slidesPerView: 2,
          spaceBetween: 51,
        },
        1280: {
          slidesPerView: 4,
        },
      }
    })
  }

  const bannerSliders = document.querySelectorAll('.banner__slider');
  bannerSliders.forEach(bannerSlider => {
    if (bannerSlider){
      let swiperLength = bannerSlider.querySelectorAll('.swiper-slide').length;
      const getNav = () => {
        if (swiperLength > 1){
          let navigation = bannerSlider.querySelector('.banner__slider-navigation');
          if (navigation && window.innerWidth > 1279){
            navigation.style.display = 'block'
          } else {
            navigation.style.display = 'none'
          }
        }
      }
      let swiper = new Swiper(bannerSlider, {
        slidesPerView: 1,
        spaceBetween: 12,
        watchOverflow: true,
        loop: swiperLength > 1,
        autoplay: {
          delay: 3500,
        },
        navigation: {
          prevEl: '.banner__slider-prev',
          nextEl: '.banner__slider-next',
        },
        on: {
          init: function () {
            setTimeout(() => {
              let activeSlide = bannerSlider.querySelector('.swiper-slide-active');
              if (activeSlide.classList.contains('video-js')){
                stopVideo()
                startVideo(activeSlide)
              } else {
                stopVideo()
              }
            }, 1);
            getNav()
          },
          resize: function () {
            getNav()
          }
        },

      })

      window.onload = function (e){
        updateSwiper()
      }

      let updateSwiper = () => {
        setTimeout(() => {
          let activeSlide = bannerSlider.querySelector('.swiper-slide-active');
          if (activeSlide.classList.contains('video-js')){
            stopVideo()
            startVideo(activeSlide)
          } else {
            stopVideo()
          }
        }, 1);
      }
      swiper.on('update', function () {
        setTimeout(() => {
          let activeSlide = bannerSlider.querySelector('.swiper-slide-active');
          if (activeSlide.classList.contains('video-js')){
            stopVideo()
            startVideo(activeSlide)
          } else {
            stopVideo()
          }
        }, 1);
      });
      swiper.update()



      let stopVideo = () => {
        let iframes = bannerSlider.querySelectorAll('iframe');
        iframes && iframes.forEach(iframe => {
          let oldSrc = iframe.getAttribute('src');
          if (!oldSrc.includes('youtube')) {
            let newSrc = oldSrc
              .replace('&js_api=1', '')
              .replace('&loop=1', '')
            iframe.setAttribute('src', newSrc);
          }else
            iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        })

        let videos = bannerSlider.querySelectorAll('video')
        videos && videos.forEach(video => {
          video.pause();
          video.currentTime = 0;
        })
      }

      let startVideo = (activeSlide) => {
        let iframe = activeSlide.querySelector('iframe');
        if (iframe){
          let oldSrc = iframe.getAttribute('src');
          let newSrc;
          if (oldSrc){
            newSrc = oldSrc;
            if (newSrc.includes('vk.com')){
              if (newSrc.includes('?')){
                newSrc = oldSrc+'&js_api=1&loop=1'
              } else {
                newSrc = oldSrc+'?js_api=1&loop=1'
              }
              iframe.setAttribute('src', newSrc);
              let vkPlayer = VK.VideoPlayer(iframe);
              vkPlayer.play()
              vkPlayer.mute()
            }

            if (newSrc.includes('youtube')){
              iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
            }
          }

        }

        let video = activeSlide.querySelector('video')
        if(video){
          video.play();
          video.setAttribute('loop', 'loop');
        }
      }

      swiper.on('slideChange', function () {
        setTimeout(() => {
          let activeSlide = bannerSlider.querySelector('.swiper-slide-active');
          if (activeSlide.classList.contains('video-js')){
            stopVideo()
            startVideo(activeSlide)
          } else {
            stopVideo()
          }
        }, 1);
      });
    }
  })

  const ticketsSliders = document.querySelectorAll('.tickets-block__tickets-slider')
  const updateTicketNavigation = (slidesCount) => {
    let navigateContainer = document.querySelector('.tickets-block__navigation');
    if ((window.innerWidth > 779) && slidesCount < 3){
      navigateContainer.style.display = "none"
    } else {
      navigateContainer.style.display = "flex"
    }
  }
  ticketsSliders.forEach(ticketsSlider => {
    if (ticketsSlider){
      let swiper = new Swiper(ticketsSlider, {
        slidesPerView: 'auto',
        loop: false,
        navigation: {
          prevEl: '.tickets-block__arrow-prev',
          nextEl: '.tickets-block__arrow-next',
        },
        breakpoints:{
          360:{
            slidesPerView: 1,
            spaceBetween: 2,
          },
          780:{
            slidesPerView: 2,
            spaceBetween: 2,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 4,
          },
        },
        on:{
          init:function (){
            setTimeout(() => {
              updateTicketNavigation(swiper.slides.length)
            },1)
          },
          resize:function (){
            updateTicketNavigation(swiper.slides.length)
          }
        }
      })
      swiper.init()
    }
  })






  const performanceSliders = document.querySelectorAll('.slider-about-js') ;
  if (performanceSliders){
    performanceSliders.forEach(performanceSlider => {
      let swiper = new Swiper(performanceSlider, {
        loop: true,
        watchOverflow: true,
        observer:true,
        observeParents: true,
        resizeObserver:true,
        navigation: {
          prevEl: document.querySelectorAll('.performance-gallery__nav-prev'),
          nextEl: document.querySelectorAll('.performance-gallery__nav-next'),
        },
        breakpoints:{
          360:{
            slidesPerView: 1,
            spaceBetween: 20,
          },
          780:{
            slidesPerView:1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 21,
          },
        },
      })

      let stopVideo = () => {
        let iframes = performanceSlider.querySelectorAll('iframe');
        iframes && iframes.forEach(iframe => {
          let oldSrc = iframe.getAttribute('src');
          if (!oldSrc.includes('youtube')) {
            let newSrc = oldSrc
              .replace('&js_api=1', '')
              .replace('&loop=1', '')
            iframe.setAttribute('src', newSrc);
          }else
            iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        })

        let videos = performanceSlider.querySelectorAll('video')
        videos && videos.forEach(video => {
          video.pause();
          video.currentTime = 0;
        })
      }
      swiper.on('slideChange', function () {
        stopVideo()
      });
    })
  }

  const mediaSlider = document.querySelector('.slider-media-js');

  if (mediaSlider) {
    let mediaImages = mediaSlider.querySelectorAll('.media-gallery__slide-img');
    let sliderWrapper = document.querySelector('.media-gallery__wrapper')
    let newArr = (mediaImages.length > 5) && ([...Array((mediaImages.length / 2))].map((e, i) => i + 1))
    if (newArr){
      newArr.forEach((mediaImage, index) => {
        let newSlide = document.createElement('div')
        newSlide.classList.add('swiper-slide')
        newSlide.insertAdjacentHTML('afterbegin',
          `
        <div class="slide-images">
            <div class="first-img"></div>
            <div class="second-img"></div>
        </div>`
        )
        sliderWrapper.append(newSlide)
      })
      let topContainers = document.querySelectorAll('.first-img')
      let bottomContainers = document.querySelectorAll('.second-img')
      let topImages = [];
      let bottomImages = [];
      mediaImages.forEach((el, i) => {
        if (i % 2 === 0){
          topImages.push(el)
          return topImages
        } else {
          bottomImages.push(el)
          return bottomImages
        }
      })
      topContainers.forEach((topContainer, index) => {
        topContainer.innerHTML = topImages[index].outerHTML
      })
      bottomContainers.forEach((bottomContainer, index) => {
        bottomContainer.innerHTML = bottomImages[index].outerHTML
      })
    } else {
      mediaImages.forEach((mediaImage, index) => {
        let newSlide = document.createElement('div')
        newSlide.classList.add('swiper-slide')
        newSlide.insertAdjacentHTML('afterbegin',
          `
        <div class="slide-images">
            <div class="main-img">${mediaImage.outerHTML}</div>
        </div>`
        )
        sliderWrapper.append(newSlide)
      })
    }



    let wrapper = mediaSlider.querySelector('.swiper-wrapper')
    if (wrapper){
      let oldBlocks = wrapper.childNodes;
      if (oldBlocks){
        oldBlocks.forEach(el => {
          if (el.classList && el.classList.contains('media-gallery__slide-img')){
            setTimeout(() => {
              el.remove()
            }, 100)
          }
        })
      }
    }


  }

  if (mediaSlider){
    let swiper = new Swiper(mediaSlider, {
      slidesPerView: 3,
      spaceBetween: 23,
      observer:true,
      observeParents: true,
      resizeObserver:true,
      watchOverflow: true,
      slidesOffsetBefore:0,
      navigation: {
        prevEl: document.querySelector('.media-gallery__nav-prev'),
        nextEl: document.querySelector('.media-gallery__nav-next'),
      },
    })

    let stopVideo = () => {
      let iframes = mediaSlider.querySelectorAll('iframe');
      iframes && iframes.forEach(iframe => {
        let oldSrc = iframe.getAttribute('src');
        if (!oldSrc.includes('youtube')) {
          let newSrc = oldSrc
            .replace('&js_api=1', '')
            .replace('&loop=1', '')
          iframe.setAttribute('src', newSrc);
        }else
          iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
      })

      let videos = mediaSlider.querySelectorAll('video')
      videos && videos.forEach(video => {
        video.pause();
        video.currentTime = 0;
      })
    }
    swiper.on('slideChange', function () {
      stopVideo()
    });
  }

  const reviewsSlider = document.querySelector('.performance-reviews__slider')
  if (reviewsSlider){
    let swiper = new Swiper(reviewsSlider, {
      watchOverflow:true,
      observer:true,
      observeParents: true,
      resizeObserver:true,
      pagination: {
        el: '.performance-reviews__pagination',
        type: 'bullets',
        dynamicBullets: true,
        clickable: true,
        observer:true,
        observeParents: true,
        resizeObserver:true,
      },
      navigation: {
        prevEl: '.performance-reviews__nav-prev',
        nextEl: '.performance-reviews__nav-next',
      },
      breakpoints:{
        360:{
          slidesPerView:1,
          spaceBetween: 30,
        },
        1280: {
          slidesPerView:3,
          spaceBetween: 30,
        },
      }
    })


    let btns = document.querySelectorAll('.review-btn-js');
    if (btns){
      btns.forEach(el => el.addEventListener('click', () => {
        el.parentElement.querySelector('.performance-review__content').classList.toggle('performance-review__content--hidden')
        el.classList.toggle('performance-review__btn--hidden')
        if (el.classList.contains('performance-review__btn--hidden')){
          el.textContent = "Свернуть"
        } else {
          el.textContent = "Подробнее"
        }
        activeIndexTab = tabsSwiper.activeIndex
        window.dispatchEvent(new Event('resize'));
      }))
    }
  }


  const articlesSwiper = document.querySelector('.performance-articles__slider')
  if (articlesSwiper){
    let swiper = new Swiper(articlesSwiper, {
      slidesPerView:1,
      spaceBetween: 30,
      watchOverflow: true,
      observer:true,
      observeParents: true,
      resizeObserver:true,
      navigation: {
        prevEl: '.performance-articles__nav-prev',
        nextEl: '.performance-articles__nav-next',
      },
      pagination: {
        el: '.performance-articles__pagination',
        type: 'bullets',
        dynamicBullets: true,
        clickable: true,
      },
    })
  }

  const newsSliders = document.querySelectorAll('.news-slider__swiper')
  if (newsSliders){
    newsSliders.forEach(newsSlider => {
      let newsSwiper = new Swiper(newsSlider, {
        spaceBetween:40,
        navigation: {
          prevEl: '.news-slider__nav-prev',
          nextEl: '.news-slider__nav-next',
        },
        breakpoints:{
          360:{
            slidesPerView:1,
          },
          780:{
            slidesPerView:2,
          },
          1280:{
            slidesPerView:3,
          }
        }
      })
    })
  }

  const thumbsSliders = document.querySelectorAll('.thumbs-slider__pagination')
  if (thumbsSliders){
    thumbsSliders.forEach(thumbsSlider => {
      let thumbsGallerySLider = new Swiper(thumbsSlider, {
        spaceBetween: 2,
        slidesPerView: 'auto',
        freeMode: true,
      })
      const sliders = document.querySelectorAll('.thumbs-slider__swiper')
      if (sliders){
        [...sliders].forEach(slider => {
          let prevBtn = slider.querySelector('.thumbs-slider__nav-prev');
          let nextBtn = slider.querySelector('.thumbs-slider__nav-next');
          let navigateContainer = slider.querySelector('.thumbs-slider__navigation');
          const  updateNav = () => {
            if (prevBtn.classList.contains('swiper-button-disabled') && nextBtn.classList.contains('swiper-button-disabled')){
              navigateContainer.style.display = "none"
            } else {
              navigateContainer.style.display = "flex"
            }
          }
          const container = slider.querySelector('.swiper-container');
          let mainSlider = new Swiper(container, {
            watchOverflow: true,
            slidesPerView:1,
            spaceBetween: 30,
            navigation: {
              nextEl: nextBtn,
              prevEl: prevBtn,
            },
            pagination: {
              el: slider.parentElement.querySelector('.thumbs-slider__pagination'),
              clickable: true,
              type: 'custom',
              bulletClass:'swiper-pagination-img'
            },
            thumbs: {
              swiper: thumbsGallerySLider
            },
            on:{
              init:function (){
                setTimeout(() => {
                  updateNav()
                },1)
              },
              resize:function (){
                updateNav()
              }
            }
          })

        })
      }

    })
  }

  const teamSLider = document.querySelector('.team-slider__container')
  if (teamSLider){
    let teamSlider = new Swiper(teamSLider, {
      // spaceBetween: 55,
      navigation: {
        prevEl: '.team-slider__prev',
        nextEl: '.team-slider__next',
      },
      breakpoints:{
        360:{
          slidesPerView: 1,
          // spaceBetween: 10,
        },
        780:{
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 4,
        },
      }
    })
  }


}

document.addEventListener('DOMContentLoaded', getSliders)





