let menuBtns = document.querySelectorAll('.mobile-menu-button-js');
import {gsap} from 'gsap';
if (menuBtns){
  menuBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      let mobileMenu = document.querySelector('.mobile-menu');
      let activeMobileMenu = document.querySelector('.mobile-menu--active');
      const togglePopup = () => {
        mobileMenu.classList.toggle('mobile-menu--active')
        document.body.classList.toggle('overflow-hidden')
      }
      if (mobileMenu){
        togglePopup()
        if (!activeMobileMenu){

          mobileMenu.addEventListener('click', (e) => {
            const contents = document.querySelectorAll('.accordion__content')
            if (e.target === mobileMenu){
              contents.forEach(content => {
                gsap.to(content, {height: 0})
              })
              mobileMenu.classList.remove('mobile-menu--active')
              document.body.classList.remove('overflow-hidden')
            }

          })
        }
      }

      let closeBtn = document.querySelector('.close-btn-js');
      if (closeBtn){
        const contents = document.querySelectorAll('.accordion__content')
        closeBtn.addEventListener('click', () => {
          contents.forEach(content => {
            gsap.to(content, {height: 0})
          })
          mobileMenu.classList.remove('mobile-menu--active')
          document.body.classList.remove('overflow-hidden')
        })
      }
    })
  })
}

