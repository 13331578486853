import Swiper from "swiper";

const stopVideoInPopup = (popup) => {
  let addPreview = (iframe) => {
    let slide = iframe.closest('.swiper-slide')
    let playBtn = slide.querySelector('.video-btn-js')
    let imgPreview = slide.querySelector('img')
    if (playBtn && imgPreview){
      playBtn.style.display = "block"
      imgPreview.style.display = "block"
    }
  }
  let iframes = popup.querySelectorAll('iframe');
  if (iframes){
    iframes.forEach(iframe => {
      let oldSrc = iframe.getAttribute('src');
      if (!oldSrc.includes('youtube')) {
        let newSrc = oldSrc
          .replace('&js_api=1', '')
          .replace('&loop=1', '')
        iframe.setAttribute('src', newSrc);
      }else{
        iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
      }
      addPreview(iframe)
      iframe.style.opacity = "0"


    })
  }

  let videos = popup.querySelectorAll('video')
  if (videos){
    videos.forEach(video => {
      video.pause();
      video.currentTime = 0;
      addPreview(video)
      video.style.opacity="0"
    })
  }

}

let getPopupSlider = (startIndex, popupSlider) => {
  setTimeout(() => {
    if (popupSlider){
      let getZoom = (popupGallery) => {
        let zoomImg = popupSlider.querySelector('.swiper-slide-zoomed');
        let btns = document.querySelectorAll('.zoom-btn')
        if (popupSlider.querySelector('.swiper-slide-active') && popupSlider.querySelector('.swiper-slide-active').classList.contains('slide-video-js')) {
          popupGallery.zoom.disable();
        } else if (!zoomImg){
          popupGallery.zoom.enable()
          popupGallery.zoom.in();
          btns.forEach(btn => {
            btn.style.display = "none"
          })

        } else {
          popupGallery.zoom.out();
          btns.forEach(btn => {
            btn.style.display = "flex"
          })
        }
      }

      let popupGallery = new Swiper(popupSlider,{
        initialSlide:startIndex || 0,
        slidesPerView:1,
        spaceBetween: 0,
        watchOverflow: true,
        allowTouchMove:window.innerWidth < 780,
        navigation: {
          prevEl: '.popup-gallery__nav-btn--prev',
          nextEl: '.popup-gallery__nav-btn--next',
        },
        // zoom: {
        //   maxRatio: 3,
        // },
        on: {
          click: function (e) {
            event.stopImmediatePropagation()
            event.stopPropagation()
            if (!e.target.closest('.swiper-zoom-container')){
              return;
            }
            //zoom
            // if (window.innerWidth > 1279 && e.target.closest('.swiper-zoom-container')){
            //   getZoom(popupGallery)
            // }


          },
          slideChange:function (){
            let content = popupSlider.closest('.popup-gallery__content')
              if(content){
                if (content.classList.contains('popup-gallery__content--video-played')){
                  content.classList.remove('popup-gallery__content--video-played')
                }
                stopVideoInPopup(popupSlider)
              }
            let btns = document.querySelectorAll('.zoom-btn')
            btns.forEach(btn => {
              btn.style.display = "flex"
            })
          }
        }
      })
      //  let zoomBtn = document.querySelector('.zoom-btn')
      // if (zoomBtn){
      //   zoomBtn.addEventListener('click', () => {
      //     if (window.innerWidth > 1279){
      //       getZoom(popupGallery)
      //     }
      //   })
      // }

      popupGallery.init()
      if (popupGallery.init){
        let actualType = popupGallery.slides[popupGallery.activeIndex].dataset.type;
        let zoomBtn = document.querySelector('.zoom-btn');
        if (zoomBtn){
          if (actualType !== 'image'){
            zoomBtn.style.display = 'none'
          } else {
            zoomBtn.style.display = 'flex'
          }
        }

        if (popupGallery.slides.length === 1){
          let slider = document.querySelector('.popup-gallery')

          let navigation = slider.querySelector('.popup-gallery__navigation')
          if (navigation){
            navigation.style.display = "none"
          }
        }
        let countPlace = document.querySelector('.popup-gallery__count-slides')
        let activeIndexPlace = document.querySelector('.popup-gallery__active-index')
        countPlace.innerText = String(popupGallery.slides.length)
        activeIndexPlace.innerText = String(popupGallery.activeIndex+1)
        popupGallery.on('slideChange', () => {
          activeIndexPlace.innerText = String(popupGallery.activeIndex+1)
          popupGallery.slideTo(popupGallery.realIndex)
          popupGallery.update()

          let actualType = popupGallery.slides[popupGallery.activeIndex].dataset.type;
          let zoomBtn = document.querySelector('.zoom-btn');
          if (zoomBtn){
            if (actualType !== 'image'){
              zoomBtn.style.display = 'none'
            } else {
              zoomBtn.style.display = 'flex'
            }
          }
        })
      }



      let buttons = document.querySelectorAll('.video-btn-js');
      if (buttons){
        buttons.forEach(el => {
          el.addEventListener('click', () => {
            el.style.display = "none"
            let preview = el.closest('.video-preview-js')
            preview.querySelector('img').style.display = "none"
            let video = preview.querySelector('video');
            if (window.innerWidth > 1279){
              popupSlider.closest('.popup-gallery__content').classList.add('popup-gallery__content--video-played')
            }

            let stopVideo = () => {
              window.addEventListener('scroll', (e) => {
                if (window.scrollY > 1552){
                  const videoAboutUs = document.querySelector('.video-preview-js');
                  let iframe = videoAboutUs.querySelector('iframe');
                  if (iframe){
                    let oldSrc = iframe.getAttribute('src');
                    if (!oldSrc.includes('youtube')) {
                      let newSrc = oldSrc
                        .replace('&js_api=1', '')
                        .replace('&loop=1', '')
                      iframe.setAttribute('src', newSrc);
                    }else
                      iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
                  }

                  let video = videoAboutUs.querySelector('video')
                  if (video){
                    video.pause();
                    video.currentTime = 0;
                  }

                }
              })

            }

            if (video){
              video.style.opacity = '1'
              video.style.zIndex = '1'
              video.play()
              // video.addEventListener('ended', () => {
              //   popupSlider.closest('.popup-gallery__content').classList.remove('popup-gallery__content--video-played')
              // })

              hiddenBtns()
              stopVideo()
            }
            let iframe = preview.querySelector('iframe');
            if (iframe){
              iframe.style.opacity = '1'
              iframe.style.zIndex = '1'
              let oldSrc = iframe.getAttribute('src');
              hiddenBtns()
              let newSrc;
              if (oldSrc){
                newSrc = oldSrc;
                if (newSrc.includes('vk.com')){
                  if (newSrc.includes('?')){
                    newSrc = oldSrc+'&js_api=1&loop=1'
                  } else {
                    newSrc = oldSrc+'?js_api=1&loop=1'
                  }
                  iframe.setAttribute('src', newSrc);
                  let vkPlayer = VK.VideoPlayer(iframe);
                  vkPlayer.play()
                }

                if (newSrc.includes('youtube')){
                  iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                }
              }

              stopVideo()
            }
          })
        })
      }

      const closeButtons = document.querySelectorAll('.popup-close-js')
      if (closeButtons){
        closeButtons.forEach(closeButton => {
          closeButton.addEventListener('click', () => {
            popupGallery.destroy();
            let zoomBtn = document.querySelector('.zoom-btn');
            if (zoomBtn) {
              zoomBtn.remove()
            }
          })
        })
      }


    }
  },50)
}

let hiddenBtns = () => {
  let popup = document.querySelector('.popup[data-popup-name="popup-gallery"]')
  let slideData = popup.querySelector('.popup-gallery__slide-data');
  let navigation = popup.querySelector('.popup-gallery__navigation');
  if ([slideData, navigation]){
    [slideData, navigation].forEach(el => {
      if (el){
        el.addEventListener('mouseover', () => {
          popup.querySelector('.popup-gallery__content').classList.add('popup-content__mouseover')
        })
        el.addEventListener('mouseout', () => {
          popup.querySelector('.popup-gallery__content').classList.remove('popup-content__mouseover')
        })
      }
    })
  }
}

let fetchContent = (id, iBlock ,type, popup, startIndex) => {
  addNavigation()
  let url = "gallery.json";
  if(window.release === true)
    url = "/ajax/gallery.php"
  fetch(`${url}?id=${id}&iBlock=${iBlock}&type=${type}`)
    .then(response => response.json())
    .then(result => {
      // popup.querySelector('.swiper-container').insertAdjacentHTML('beforeBegin',`
      //     <button class="zoom-btn">
      //                       <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      //                         <path d="M29.6875 20.3125H23.4375V14.0625H20.3125V20.3125H14.0625V23.4375H20.3125V29.6875H23.4375V23.4375H29.6875V20.3125Z" fill="white"/>
      //                         <path d="M35.0781 32.8125C37.6532 29.7498 39.0642 25.8764 39.0625 21.875C39.0625 18.4756 38.0545 15.1526 36.1659 12.3261C34.2773 9.49967 31.593 7.29671 28.4524 5.99583C25.3118 4.69495 21.8559 4.35458 18.5219 5.01776C15.1878 5.68094 12.1253 7.3179 9.72161 9.72161C7.3179 12.1253 5.68094 15.1878 5.01776 18.5219C4.35458 21.8559 4.69495 25.3118 5.99583 28.4524C7.29671 31.593 9.49967 34.2773 12.3261 36.1659C15.1526 38.0545 18.4756 39.0625 21.875 39.0625C25.8764 39.0642 29.7498 37.6532 32.8125 35.0781L44.6719 46.875L46.875 44.6719L35.0781 32.8125ZM21.875 35.9375C19.0937 35.9375 16.3749 35.1128 14.0623 33.5676C11.7497 32.0223 9.94731 29.8261 8.88295 27.2565C7.81859 24.6869 7.54011 21.8594 8.08272 19.1315C8.62532 16.4037 9.96464 13.898 11.9313 11.9313C13.898 9.96464 16.4037 8.62532 19.1315 8.08272C21.8594 7.54011 24.6869 7.81859 27.2565 8.88295C29.8261 9.94731 32.0223 11.7497 33.5676 14.0623C35.1128 16.3749 35.9375 19.0937 35.9375 21.875C35.9375 25.6046 34.4559 29.1815 31.8187 31.8187C29.1815 34.4559 25.6046 35.9375 21.875 35.9375Z" fill="white"/>
      //                       </svg>
      //                       <div class="zoom-btn__zoom-text">увеличить</div>
      //     </button>`)
      result.content.forEach(el => {
        if (el.type === "image"){
          popup.querySelector('.swiper-wrapper').insertAdjacentHTML('beforeend', `
                    <div class="swiper-slide popup-gallery__slide" data-type="${el.type}">
                      <div class="swiper-zoom-container popup-gallery__img">
                          <img src="${el.image}">
                      </div>
                      <div class="popup-gallery__slide-data">${el.title}</div>
                    </div>
                `)
        } else if (el.type === "video"){
          popup.querySelector('.swiper-wrapper').insertAdjacentHTML('beforeend', `
                    <div class="swiper-slide popup-gallery__slide" data-type="${el.type}">
                      <div class="popup-gallery__video-preview video-preview-js video-container">
                          <button class="popup-gallery__play-btn video-btn-js video-container__btn">
                            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 60L40 94.641L40 25.359L100 60Z" fill="#D6B048"/>
                              <circle cx="60" cy="60" r="59" stroke="#D6B048" stroke-width="2"/>
                            </svg>
                          </button>
                          <img src="${el.image}">
                          <video (playsinline="" controls=true)>
                            <source src="${el.video}" type="video/mp4">
                          </video>
                      </div>

                      <div class="popup-gallery__slide-data">${el.title}</div>
                    </div>
                `)
        }
        else if (el.type === "iframe"){
          popup.querySelector('.swiper-wrapper').insertAdjacentHTML('beforeend', `
                    <div class="swiper-slide popup-gallery__slide" data-type="${el.type}">
                      <div class="popup-gallery__video-preview video-preview-js video-container">
                          <button class="popup-gallery__play-btn video-btn-js video-container__btn">
                            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 60L40 94.641L40 25.359L100 60Z" fill="#D6B048"/>
                              <circle cx="60" cy="60" r="59" stroke="#D6B048" stroke-width="2"/>
                            </svg>
                          </button>
                          <img src="${el.image}">
                          ${el.iframe}
                      </div>
                      <div class="popup-gallery__slide-data">${el.title}</div>
                    </div>
                `)
        }
      })
      popup.classList.toggle('popup--active')
      document.body.classList.toggle('overflow-hidden')
    })
    .then(() => {
      const popupSlider = document.querySelector('.popup-gallery__slider')
      getPopupSlider(startIndex, popupSlider)
    })

}

let addNavigation = () => {
  let slider = document.querySelector('.popup-gallery')
  if (slider){
    let navigation = slider.querySelector('.popup-gallery__navigation')
    if (navigation){
      navigation.style.display = "flex"
    }
  }
}
document.addEventListener('DOMContentLoaded', () => {
  let sliderPopups = document.querySelectorAll('.popup-gallery-js');
  let popup = document.querySelector('.popup[data-popup-name="popup-gallery"]')
  if (sliderPopups){
    sliderPopups.forEach(sliderPopup => {
      let slides = sliderPopup.querySelectorAll('.swiper-slide')

      if (slides){
        slides.forEach(slide => {
          slide.addEventListener('click', (e) => {
            let swiperContainer = e.target.closest('.swiper-container')
            let wrapper
            if (swiperContainer){
              wrapper = swiperContainer.querySelector('div[data-id]')
            }
            let id = slide.getAttribute('data-id') || wrapper.getAttribute('data-id')
            let iBlock = slide.getAttribute('data-iblock') || wrapper.getAttribute('data-iblock')
            let type = slide.getAttribute('data-type') ||  wrapper.getAttribute('data-type')
            let startIndex = slide.getAttribute('data-swiper-slide-index') || [...slides].findIndex(item => item.classList.contains('swiper-slide-active'))
            fetchContent(id, iBlock, type, popup, startIndex)
          })
        })
      }
    })
  }


  let filePopups = document.querySelectorAll('.popup-file-js')
  if (filePopups){
    filePopups.forEach(el => {
      el.addEventListener('click', () => {
        let id = el.getAttribute('data-id')
        let iBlock = el.getAttribute('data-iblock')
        let type = el.getAttribute('data-type')
        let startIndex = 0
        fetchContent(id, iBlock, type, popup, startIndex)
      })
    })
  }






})


