document.addEventListener('DOMContentLoaded', () => {
  const controls = [
    'play',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
  ];
  let audios = document.querySelectorAll('audio')
  if (audios){
    const players = Array.from(audios).map((audio) => new Plyr(audio,{
        controls,
        volume:0.5
      })
    );
  }
})



