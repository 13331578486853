const changeWordPattern = () => {
  let wordPattern = document.querySelector('.word-pattern');
  let footer = document.querySelector('.footer');
  let mailingForm = document.querySelector('.mailing-form');
  let main = document.querySelector('.main');
  let banner = document.querySelector('.banner');
  let mainPage = document.querySelector('.main-page');
  let wrapper
  if (footer && main && mailingForm){
    if (banner){
      if (mainPage){
        wrapper = main.clientHeight - banner.clientHeight
      } else {
        wrapper = main.clientHeight - footer.clientHeight - mailingForm.clientHeight - banner.clientHeight
      }
    } else {
      wrapper = main.clientHeight - footer.clientHeight - mailingForm.clientHeight
    }
  }
  if (wordPattern){
    if (window.innerWidth > 1499){
      if (wrapper >= 1550){
        wordPattern.style.opacity = "1"
      } else {
        wordPattern.style.opacity = "0"
      }
    } else {
      wordPattern.style.opacity = "0"
    }
  }

}

document.addEventListener('DOMContentLoaded', changeWordPattern)
window.addEventListener('resize', changeWordPattern)
