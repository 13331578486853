import {gsap} from 'gsap';

document.addEventListener('DOMContentLoaded', () => {
  const accordionList = document.querySelectorAll('.accordion')
  accordionList && accordionList.forEach(accordion => {
    const accordionItems = accordion.querySelectorAll('.accordion__item')
    let activeItem = null;
    const toggleItem = (item) => {
      const toggle = item.querySelector('.accordion__toggle')
      const content = item.querySelector('.accordion__content')
      if (activeItem === item) {
        toggle.classList.remove('accordion__toggle--active')
        gsap.to(content, {height: 0})
        activeItem = null
        return;
      }
      toggle.classList.add('accordion__toggle--active')
      gsap.to(content, {height: 'auto'})
      if(activeItem) toggleItem(activeItem)
      activeItem = item
    }
    accordionItems && accordionItems.forEach((item) => {
      const toggle = item.querySelector('.accordion__toggle')
      const content = item.querySelector('.accordion__content')
      if (toggle && content) {
        item.addEventListener('click', (e) => {
          e.preventDefault()
          if (e.target instanceof HTMLAnchorElement){
            window.location = e.target.href
          }  else {
            toggleItem(item)
          }

        })
      }
    })
  })
})
