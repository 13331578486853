document.addEventListener('DOMContentLoaded', () => {
  let perfomanceCards = document.querySelectorAll('.performance-card')
  perfomanceCards.forEach(el => {
    if (window.innerWidth >= 1280){
      el.addEventListener('mouseover', () => {
        el.classList.add('performance-card--active')
      })
      el.addEventListener('mouseout', () => {
        el.classList.remove('performance-card--active')
      })
    } else {
      el.addEventListener('touchstart', (e) => {
        if (!e.target.classList.contains('button')){
          el.classList.add('performance-card--active')
        }
      })
      el.addEventListener('touchend', (e) => {
        if (el.classList.contains('performance-card--active')){
          el.classList.remove('performance-card--active')
        }
      })
    }



  })
})
