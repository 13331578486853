document.addEventListener('DOMContentLoaded',() => {
  let historyLinesContainers = document.querySelectorAll('.history-line-container-js')
  if (historyLinesContainers){
    historyLinesContainers.forEach(el => {
      let lineHeight = el.clientHeight - 61.5  //длина линии в пикселях
      let svgComp = el.querySelector('.history-page__content-left-img').querySelector('svg');
      let scale = (lineHeight / 118)
      svgComp.style.transform = `scaleY(${scale})`
    })
  }
})
