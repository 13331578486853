document.addEventListener('DOMContentLoaded', () => {
  const clearData = (popup) => {
    let reviewContainer = popup.querySelector('.form-review__review-container')
    let reviewAnswer = popup.querySelector('.form-review__answer')
    if (reviewContainer){
      reviewContainer.style.display = "block"
    }
    if (reviewAnswer){
      reviewAnswer.style.display = "none"
    }
    let inputs = popup.querySelectorAll('input')
    let textareas = popup.querySelectorAll('textarea')
    let blocks = [...inputs, ...textareas]
    if (blocks){
      blocks.forEach(block => {
        block.value = ""
      })
    }
    let checkboxes = popup.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    })

    let swiperSlides = popup.querySelectorAll('.swiper-slide')
    if (swiperSlides){
      swiperSlides.forEach(el => {
        el.remove()
      })
    }
  }

    const popupList = document.querySelectorAll('[data-popup-name]')
    if (popupList){
      popupList.forEach(popup => {
        const popupName = popup.dataset.popupName
        if (popupName) {
          const togglePopup = () => {
            popup.classList.toggle('popup--active')
            document.body.classList.toggle('overflow-hidden')
            clearData(popup)
            let playedContent = popup.querySelector('.popup-gallery__content--video-played');
            if (playedContent){
              playedContent.classList.remove('popup-gallery__content--video-played')
            }
          }
          const popupButtons = document.querySelectorAll(`[data-popup="${popupName}"]`)
          popupButtons && popupButtons.forEach(button => {
            button.addEventListener('click', togglePopup)
          })
          const closeButtons = popup.querySelectorAll('.popup-close-js')
          if (closeButtons){
            closeButtons.forEach(closeButton => {
              closeButton.addEventListener('click', togglePopup)
            })
          }
          popup && popup.addEventListener('click', (e) => {
            if (e.target === popup){
              togglePopup()
            }
          })
        }
      })
    }


})
