const getFilters = (filter) => {
  let inputs = filter.querySelectorAll('input[type="checkbox"]:checked, input[type="radio"]:checked')
  let filterTitle = filter.querySelector('.filter__title')
  if (inputs.length){
    let values = [...inputs].map(el => {
      let parentEl = el.parentElement;
      if (parentEl){
        let label = el.parentElement.querySelector('.filter__label')
        return label.textContent
      }
    })
    if (filterTitle){
      filterTitle.querySelector('.filter__title-name').style.display="none"
      filterTitle.querySelector('.filter__checked-filters').textContent = values.join(', ')
    }
  } else {
    if (filterTitle){
      filterTitle.querySelector('.filter__title-name').style.display="block"
      filterTitle.querySelector('.filter__checked-filters').textContent = ''
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  let filters = document.querySelectorAll('.filter');
  if (filters){
    filters.forEach(filter => {
      getFilters(filter)
      filter.addEventListener('click', (e) => {
        if (e.target.parentElement.classList.contains('filter--active')){
          e.target.parentElement.classList.remove('filter--active');
        } else if (document.querySelector('.filter--active')) {
          document.querySelector('.filter--active').classList.remove('filter--active')
          filter.classList.add('filter--active')
        } else {
          e.target.parentElement.classList.add('filter--active');
        }
        let dropdownMenu = filter.querySelector('.filter__dropdown-menu');
        let filterList = filter.querySelector('.filter__list');
        if (dropdownMenu && filterList){
          dropdownMenu.style.width = `calc(${filterList.getBoundingClientRect().width}px + 37px)`
        }
        let activeFilter = document.querySelector('.filter--active')
        if (activeFilter){
          document.addEventListener('click',(e) => {
            if (!e.target.classList.contains('filter__top')){
              let activeFilter = document.querySelector('.filter--active')
              getFilters(filter)
              if (e.target.closest('.filter') === null && activeFilter){
                activeFilter.classList.remove('filter--active')
              }
            }
            if (e.target.type && e.target.type === 'radio' && activeFilter){
              activeFilter.classList.remove('filter--active')
            }


          })
        }

      })
    })
  }


  let buttonsResetFilters = document.querySelectorAll('.button--filter')
  if (buttonsResetFilters){
    buttonsResetFilters.forEach(el => {
      el.addEventListener('click', () => {
        let filters  = document.querySelectorAll('.filter')
        if (filters){
          [...filters].forEach(el => {
            setTimeout(() => {
              let inputs = el.querySelectorAll('input')
              inputs.forEach(input => {
                input.checked = false
              })
              console.log('inputs', inputs)
              el.querySelector('.filter__checked-filters').textContent = "";
              el.querySelector('.filter__title-name').style.display = "block";
            }, 1)

          })

        }
      })


    })
  }

})

